import { useState } from "react";
import { emailRegistration } from "../../../managers/authManager";
import { Link } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "./Register.css"
import { WaitingPage } from "./WaitingPage";
import { Checkbox } from "@mui/material";

export default function Register({ setLoggedInUser }: any) {
  const [email, setEmail] = useState("");

  const [registrationFailure, setRegistrationFailure] = useState(false);
  const [waitingPage, setWaitingPage] = useState(false)
  const [userAccept, setUserAccept] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (userAccept === false) {
      window.alert("You must accept user agreement to register account")
      return
    }
    else if (userAccept === true && email !== ""){
      emailRegistration(email).then((pass) => {
        if (pass) {
          setWaitingPage(true)
        }
        else {
          setRegistrationFailure(true)
        }
      })
    }

    return window.alert("Please input an email")

  };

  const handleCheckbox = (e:any) => {
    setUserAccept(e.target.checked)
  }

  return (
    <div className="r-main">
      {waitingPage === true ?
        (
          <>
            <WaitingPage email={email} />
          </>
        ) : (
          <div className="r-container container" style={{ maxWidth: "500px", marginTop: "15rem", scale: "1.3" }}>
            <div className="r-header">
              Sign Up
            </div>
            <div className="r-form-groups">
              <FormGroup className="fg-email">
                <Label className="r-label">Email</Label>
                <Input
                  className="r-input"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup className="r-fg-ug">
                <Label className="r-label"><Link to={"/privacy-and-consent"}>Accept User Agreement</Link></Label>
                <Checkbox onChange={(e) => handleCheckbox(e)}/>
              </FormGroup>
            </div>
            <p style={{ color: "red" }} hidden={!registrationFailure}>
              Registration Failure
            </p>
            <div className="r-footer">
              <Button
                className="r-button"
                color="primary"
                onClick={handleSubmit}
              >
                Register
              </Button>
              <p className="r-f-note">
                Already signed up? Log in <Link to="/login">here</Link>
              </p>
              <p className="r-f-note">
                Register with a  <Link to="enp">Custom Key</Link>
              </p>
            </div>
          </div>
        )}
    </div>
  );
}
