import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login, sendEmailLoginToken } from "../../../managers/authManager";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import "./Login.css"
import { WaitingPage } from "./WaitingPage";

export default function Login({ setLoggedInUser }:any) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [failedLogin, setFailedLogin] = useState(false);
  const [waitingPage, setWaitingPage] = useState(false)

  const handleSubmit = (e:any) => {
    e.preventDefault();
    sendEmailLoginToken(email).then((pass) => {
      if(pass){
        setWaitingPage(true)
      }
      else{
        setFailedLogin(true)
      }
    })
  };

  return (
    <div className="l-main">
      {waitingPage === true ? 
        (
          <>
            <WaitingPage email={email}/>
          </>
        ):(
            <div className="l-container container" style={{ maxWidth: "500px", marginTop: "15rem", scale: "1.3" }}>
              <div className="l-header">Login</div>
              <FormGroup className="l-formgroup">
                <Label className="l-email-label">Email</Label>
                <Input className="l-email-input"
                  invalid={failedLogin}
                  type="text"
                  value={email}
                  onChange={(e:any) => {
                    setFailedLogin(false);
                    setEmail(e.target.value);
                  }}
                />
              </FormGroup>

              <div className="l-footer">
                <Button className="l-button" color="primary" onClick={handleSubmit}>
                  Send Login Email
                </Button>

                <p className="l-f-note">
                  Not signed up? Register <Link to="/register">here</Link>
                </p>
                <p className="l-f-note">
                  Can't Login? <Link to="enp">Enter Recovery Key</Link>
                </p>
              </div>

            </div>
        )}
    </div>
  );
}
