import { Button, Card, IconButton, styled, TextField } from "@mui/material"
import PublishIcon from '@mui/icons-material/Publish';
import { AddPhotoAlternate, CloudUpload } from "@mui/icons-material";

export const NewQuestion = (
    {
        question,
        responseType,
        handleSubmit,
        handleInputChange,
        responseStates,
        i,
    }: any
) => {

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Ensure file exists
        const allowedTypes = ['image/png', 'image/jpeg'];
        if (!file || !allowedTypes.includes(file.type)) {
            console.error('No file selected');
            return;
        }

        let imageType = "";

        if(file.type === "image/png") imageType = "image/png"
        else if(file.type === "image/jpeg") imageType = "image/jpeg"
    
        const reader = new FileReader();
    
        reader.onloadend = () => {
            if (reader.result) {
                const base64String = reader.result.toString().split(',')[1]; // Remove metadata
                console.log(base64String);
                const fullImageSrc = `data:${imageType};base64,${base64String}`;

                handleInputChange(event, question.id, fullImageSrc) // pls
                
            } else {
                console.error('Error reading file');
            }
        };
    
        reader.onerror = () => {
            console.error('Error loading file');
        };
    
        reader.readAsDataURL(file);
    };
    

    // If it is a text response
    if (responseType === 1 || responseType === 2 || responseType === 4 || responseType === 0) {
        return (
            <div className='q-container'>
                <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF", }} className='q-card'>
                    <div className='q-body'>
                        {i + 1} {question.body}
                    </div>
                    <div className='q-submit'>
                        <IconButton disabled={false} id={`submit-btn-${question.id.toString()}`} color='primary' size='small' onClick={() => handleSubmit(question, question.id)} className='q-save-btn'><PublishIcon fontSize='large' /></IconButton>
                    </div>
                    <div className='q-response'>
                        <TextField disabled={false} id={`submit-input-${question.id.toString()}`} onChange={(e) => handleInputChange(e, question.id)} className='q-response-input' size='small' type='text' value={responseStates[question.id] || ''}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") handleSubmit(question, question.id)
                            }}
                        />
                    </div>
                </Card>
            </div>
        )
    }

    else if (responseType === 6) {
        return (
            <div className='q-container q-container-image'>
                <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF", }} className="q-card">
                    <div className="q-body">
                        {i + 1} {question.body}
                    </div>
                    <div className='q-submit'>
                        <IconButton disabled={false} id={`submit-btn-${question.id.toString()}`} color='primary' size='small' onClick={() => handleSubmit(question, question.id)} className='q-save-btn'><PublishIcon fontSize='large' /></IconButton>
                    </div>
                    <div className="q-response">
                        <Button component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<AddPhotoAlternate />}
                        >
                            Upload Image
                            <VisuallyHiddenInput
                                accept="image/png, image/jpeg"
                                type="file"
                                multiple
                                onChange={(e) => {handleImageUpload(e)}}
                            />
                        </Button>
                    </div>
                </Card>
            </div>
        )
    }

    else {
        return null;
    }

}

/*

*/