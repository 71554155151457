import "./MobilePrivacyPage.css"

export const MobilePrivacyPage = () => {
    return (
        <div className="mp-main">
            <div className="mp-header">
                <div className="mp-h-header">
                    Privacy Policy
                </div>
            </div>
            <div className="mp-body">
                <div className="mp-b-header">
                    This privacy policy will explain how Loudplanes.org uses the personal data we collect from you when you use our website.
                </div>
                <div className="mp-b-index">
                    <div className="mp-b-i-header">
                        Topics:
                    </div>
                    <ul>
                        <li>
                            What data do we collect?
                        </li>
                        <li>
                            How do we collect your data?
                        </li>
                        <li>
                            How will we use your data?
                        </li>
                        <li>
                            How do we store your data?
                        </li>
                        <li>
                            Marketing
                        </li>
                        <li>
                            What are your data protection rights?
                        </li>
                        <li>
                            What are cookies?
                        </li>
                        <li>
                            What types of cookies do we use?
                        </li>
                        <li>
                            How to manage your cookies
                        </li>
                        <li>
                            Privacy policies of other websites
                        </li>
                        <li>
                            Changes to our privacy policy
                        </li>
                        <li>
                            How to contact us
                        </li>
                    </ul>
                </div>
                {/* 001 */}
                <div className="mp-b-01 p-b-000">

                    <div className="mp-b-01-header p-b-000-header">
                        What data do we collect?
                    </div>
                    <div className="mp-b-01-body p-b-000-body">
                        GameOverPlan.com collects the following data:
                        <ul>
                            <li>
                                Personal identification information (Name, email address, phone number, etc.)
                            </li>
                        </ul>
                    </div>

                </div>
                {/* 002 */}
                <div className="mp-b-02 p-b-000">

                    <div className="mp-b-02-header p-b-000-header">
                        How do we collect your data?
                    </div>
                    <div className="mp-b-02-body p-b-000-body">
                        You directly provide Gameoverplan.com with most of the data we collect. We collect data and process data when you:
                        <ul>
                            <li>
                                Register online or sign up.
                            </li>
                            <li>
                                Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.
                            </li>
                            <li>
                                Use or view our website via your browser's cookies.
                            </li>
                        </ul>
                    </div>

                </div>

                {/* 003 */}
                <div className="mp-b-03 p-b-000">

                    <div className="mp-b-03-header p-b-000-header">
                        How will we use your data?
                    </div>
                    <div className="mp-b-03-body p-b-000-body">
                        Gameoverplan.com collects your data so that we can:
                        <ul>
                            <li>
                                Manage your account.
                            </li>
                            <li>
                                Email you with special offers on other products and services we think you might like.
                            </li>
                        </ul>
                    </div>

                </div>

                {/* 004 */}
                <div className="mp-b-04 p-b-000">

                    <div className="mp-b-04-header p-b-000-header">
                        How will we store your data?
                    </div>
                    <div className="mp-b-04-body p-b-000-body">
                        <ul>
                            <li>
                                Gameoverplan.com securely stores your data in Google storage (/ WRONG \)
                            </li>
                            <li>
                                Gameoverplan.com will keep your data until it is requested to be deleted. Once requested to be will deleted, your data will be deleted within 30 days from the request.
                            </li>
                        </ul>
                    </div>

                </div>

                {/* 005 */}
                <div className="mp-b-05 p-b-000">

                    <div className="mp-b-05-header p-b-000-header">
                        Marketing
                    </div>
                    <div className="mp-b-05-body p-b-000-body">
                        <ul>
                            <li>
                                Gameoverplan.com would like to send you information about products and services of ours that we think you might like.
                            </li>
                            <li>
                                You have the right at any time to stop Gameoverplan.com from contacting you for marketing purposes or giving your data to other members of Gameoverplan.com.
                            </li>
                            <li>
                                If you no longer wish to be contacted for marketing purposes, please fill out a contact request form.
                            </li>
                        </ul>
                    </div>

                </div>

                {/* 006 */}
                <div className="mp-b-06 p-b-000">

                    <div className="mp-b-06-header p-b-000-header">
                        What are your data protection rights?
                    </div>
                    <div className="mp-b-06-body p-b-000-body">
                        Gameoverplan.com would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                        <ul>
                            <li>
                                <span>The right to access: </span>
                                You have the right to request Gameoverplan.com for copies of your personal data. We may charge you a small fee for this service.
                            </li>
                            <li>
                                <span>The right to rectification: </span>
                                You have the right to request that Gameoverplan.com correct any information you believe is inaccurate. You also have the right to request Gameoverplan.com to complete the information you believe is incomplete.
                            </li>
                            <li>
                                <span>The right to erasure: </span>
                                You have the right to request that Gameoverplan.com erase your personal data, under certain conditions.
                            </li>
                            <li>
                                <span>The right to restrict processing: </span>
                                You have the right to request that Gameoverplan.com restrict the processing of your personal data, under certain conditions.
                            </li>
                            <li>
                                <span>The right to object to processing: </span>
                                You have the right to object to Gameoverplan.com processing of your personal data, under certain conditions.
                            </li>
                            <li>
                                <span>The right to data portability: </span>
                                You have the right to request that Gameoverplan.com transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                            </li>
                        </ul>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us by filling out our contact form.
                    </div>

                </div>

                {/* 007 */}
                <div className="mp-b-07 p-b-000">

                    <div className="mp-b-07-header p-b-000-header">
                        Cookies
                    </div>
                    <div className="mp-b-07-body p-b-000-body">
                        <div>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology</div>
                        <div>For further information, visit allaboutcookies.org.</div>
                    </div>

                </div>


                {/* 008 */}
                <div className="mp-b-08 p-b-000">

                    <div className="mp-b-08-header p-b-000-header">
                        How do we user Cookies?
                    </div>
                    <div className="mp-b-08-body p-b-000-body">
                        Gameoverplan.com uses cookies in a range of ways to improve your experience on our website, including:
                        <ul>
                            <li>
                                Keeping you signed in
                            </li>
                            <li>
                                Understanding how you use our website
                            </li>
                        </ul>
                    </div>

                </div>

                {/* 009 */}
                <div className="mp-b-09 p-b-000">

                    <div className="mp-b-09-header p-b-000-header">
                        What types of cookies do we use?
                    </div>
                    <div className="mp-b-09-body p-b-000-body">
                        There are a number of different types of cookies, however, our website uses:
                        <ul>
                            <li>
                                <span>Functionality: </span>Gameoverplan.com uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.
                            </li>
                            <li>
                                <span>Advertising: </span>Gameoverplan.com uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Gameoverplan.com sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.
                            </li>
                        </ul>
                    </div>

                </div>

                {/* 010 */}
                <div className="mp-b-10 p-b-000">

                    <div className="mp-b-10-header p-b-000-header">
                        How to manage cookies
                    </div>
                    <div className="mp-b-10-body p-b-000-body">
                        You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
                    </div>

                </div>

                {/* 011 */}
                <div className="mp-b-11 p-b-000">

                    <div className="mp-b-11-header p-b-000-header">
                        Privacy policies of other websites
                    </div>
                    <div className="mp-b-11-body p-b-000-body">
                        The Gameoverplan.com website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
                    </div>

                </div>

                {/* 012 */}
                <div className="mp-b-12 p-b-000">

                    <div className="mp-b-12-header p-b-000-header">
                        Changes to our privacy policy
                    </div>
                    <div className="mp-b-12-body p-b-000-body">
                        Gameoverplan.com keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on May 24th, 2024.
                    </div>

                </div>

                {/* 013 */}
                <div className="mp-b-13 p-b-000">

                    <div className="mp-b-13-header p-b-000-header">
                        How to contact us
                    </div>
                    <div className="mp-b-13-body p-b-000-body">
                        If you have any questions about Gameoverplan.com's privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us by filling out our contact form.
                    </div>

                </div>

            </div>
            {/* FOOTER */}
            <div className="mp-footer">
                <div>
                    COPYRIGHT © Gameoverplan.com. ALL RIGHTS RESERVED.
                </div>
                <div>
                    {/* Maybe some links? */}
                </div>
            </div>
        </div>
    )
}