import { useState } from "react";
import { emailRegistration } from "../../../managers/authManager";
import { Link } from "react-router-dom";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import "./MobileRegister.css"
import { MobileWaitingPage } from "./MobileWaitingPage";
import { Checkbox } from "@mui/material";

export default function MobileRegister({ setLoggedInUser }: any) {
  const [email, setEmail] = useState("");
  const [userAccept, setUserAccept] = useState(false)

  const [registrationFailure, setRegistrationFailure] = useState(false);
  const [waitingPage, setWaitingPage] = useState(false)


  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (userAccept === false) {
      window.alert("You must accept user agreement to register account")
      return
    }
    else if (userAccept === true && email !== "") {
      emailRegistration(email).then((pass) => {
        if (pass) {
          setWaitingPage(true)
        }
        else {
          setRegistrationFailure(true)
        }
      })
    }

    return window.alert("Please input an email")

  };

  const handleCheckbox = (e: any) => {
    setUserAccept(e.target.checked)
  }

  return (
    <div className="mobile-reg-main">
      {waitingPage === true ?
        (
          <>
            <MobileWaitingPage email={email} />
          </>
        ) : (
          <div className="mobile-reg-container container">
            <div className="mobile-reg-header">
              Sign Up
            </div>
            <div className="m-r-form-groups">
              <FormGroup className="m-r-email">
                <Label className="mobile-reg-label">Email</Label>
                <Input
                  className="mobile-reg-input"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup className="m-r-fg-ua">
                <Label className="r-label"><Link to={"/privacy-and-consent"}>Accept User Agreement</Link></Label>
                <Checkbox onChange={(e) => handleCheckbox(e)} />
              </FormGroup>
            </div>
            <p style={{ color: "red" }} hidden={!registrationFailure}>
              Registration Failure
            </p>
            <div className="mobile-reg-footer">
              <Button
                className="mobile-reg-button"
                color="primary"
                onClick={handleSubmit}
              >
                Register
              </Button>
              <p className="mobile-reg-f-note">
                Already signed up? Log in <Link to="/login">here</Link>
              </p>
              <p className="mobile-reg-f-note">
                Register with a  <Link to="enp">Custom Key</Link>
              </p>
            </div>
          </div>
        )}
    </div>
  );
}
