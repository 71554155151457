import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login, sendEmailLoginToken } from "../../../managers/authManager";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { MobileWaitingPage } from "./MobileWaitingPage";
import "./MobileLogin.css"

export default function MobileLogin({ setLoggedInUser }:any) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [failedLogin, setFailedLogin] = useState(false);
  const [waitingPage, setWaitingPage] = useState(false)

  const handleSubmit = (e:any) => {
    e.preventDefault();
    sendEmailLoginToken(email).then((pass) => {
      if(pass){
        setWaitingPage(true)
      }
      else{
        setFailedLogin(true)
      }
    })
  };

  return (
    <div className="mobile-login-main">
      {waitingPage === true ? 
        (
          <>
            <MobileWaitingPage email={email}/>
          </>
        ):(
            <div className="mobile-login-container container">
              <div className="mobile-login-header">Login</div>
              <FormGroup className="mobile-login-formgroup">
                <Label className="mobile-login-email-label">Email</Label>
                <Input className="mobile-login-email-input"
                  invalid={failedLogin}
                  type="text"
                  value={email}
                  onChange={(e:any) => {
                    setFailedLogin(false);
                    setEmail(e.target.value);
                  }}
                />
              </FormGroup>

              <div className="mobile-login-footer">
                <Button className="mobile-login-button" color="primary" onClick={handleSubmit}>
                  Send Login Email
                </Button>

                <p className="mobile-login-f-note">
                  Not signed up? Register <Link to="/register">here</Link>
                </p>
                <p className="mobile-login-f-note">
                  Can't Login? <Link to="enp">Enter Recovery Key</Link>
                </p>
              </div>

            </div>
        )}
    </div>
  );
}
