import { Button, Card, IconButton, styled, TextField, Tooltip } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import { AddPhotoAlternate } from "@mui/icons-material";

export const EditQuestion = (
    {
        question,
        userQuestion,
        responseStates,
        handleSave,
        handleInputChange,
        loggedInUser,
        i
    }: any
) => {

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Ensure file exists
        const allowedTypes = ['image/png', 'image/jpeg'];
        if (!file || !allowedTypes.includes(file.type)) {
            console.error('No file selected');
            return;
        }

        let imageType = "";

        if (file.type === "image/png") imageType = "image/png"
        else if (file.type === "image/jpeg") imageType = "image/jpeg"

        const reader = new FileReader();

        reader.onloadend = () => {
            if (reader.result) {
                const base64String = reader.result.toString().split(',')[1]; // Remove metadata
                console.log(base64String);
                const fullImageSrc = `data:${imageType};base64,${base64String}`;

                handleInputChange(event, question.id, fullImageSrc) // pls

            } else {
                console.error('Error reading file');
            }
        };

        reader.onerror = () => {
            console.error('Error loading file');
        };

        reader.readAsDataURL(file);
    };

    if (question.responseTypeId === 6) {
        return null; // This code below is unreachable on purpose. Im taking a pause on implementing image response type editing because it is a pain in the ass
        return (
            <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF" }} className='q-card'>
                <div className='q-body'>
                    {i + 1}: {question.body}
                </div>
                <div className='q-save'> {/*=================BELOW, checks if user has inputed any new info into the edit field=================*/}
                    {userQuestion.response === responseStates[userQuestion.questionId] || responseStates[userQuestion.questionId] === '' || responseStates[userQuestion.questionId] === undefined ?
                        <Tooltip title='Save'>
                            <IconButton color='primary' size='large' disabled className='q-save-btn'><SaveIcon fontSize='large' /></IconButton>
                        </Tooltip>
                        :
                        <Tooltip title='Save'>
                            <IconButton color='primary' size='large' onClick={() => handleSave(loggedInUser.id, userQuestion)} className='q-save-btn'><SaveIcon fontSize='large' /></IconButton>
                        </Tooltip>
                    }

                </div>
                <div className="q-response">
                    <Button component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<AddPhotoAlternate />}
                    >
                        Upload Image
                        <VisuallyHiddenInput
                            accept="image/png, image/jpeg"
                            type="file"
                            multiple
                            onChange={(e: any) => { handleImageUpload(e) }}
                        />
                    </Button>
                </div>
            </Card>
        )
    }

    return (
        <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF" }} className='q-card'>
            <div className='q-body'>
                {i + 1}: {question.body}
            </div>
            <div className='q-save'> {/*=================BELOW, checks if user has inputed any new info into the edit field=================*/}
                {userQuestion.response === responseStates[userQuestion.questionId] || responseStates[userQuestion.questionId] === '' || responseStates[userQuestion.questionId] === undefined ?
                    <Tooltip title='Save'>
                        <IconButton color='primary' size='large' disabled className='q-save-btn'><SaveIcon fontSize='large' /></IconButton>
                    </Tooltip>
                    :
                    <Tooltip title='Save'>
                        <IconButton color='primary' size='large' onClick={() => handleSave(loggedInUser.id, userQuestion)} className='q-save-btn'><SaveIcon fontSize='large' /></IconButton>
                    </Tooltip>
                }

            </div>
            <div className='q-response'>
                <TextField onChange={(e) => handleInputChange(e, userQuestion.questionId)} placeholder={userQuestion.response} className='q-response-input' size='small' type='text' value={responseStates[userQuestion.questionId] || ''}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") handleSave(loggedInUser.id, userQuestion)
                    }}
                />
            </div>
        </Card>
    )
}