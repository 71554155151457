import { Card } from "@mui/material"

export const MobileSingleResponseView = ({ question, responseType }: any) => {

    const userQuestion = question.userQuestions[0]

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'black',
        color: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    console.log(userQuestion.response)

    if (responseType === 6) {
        return (
            <div className='m-q-container'>
                <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF" }} className='m-q-card'>
                    <div className='m-q-body'>
                        {question.body}
                    </div>
                    <div className='m-q-image-container'>
                        <img className="m-q-image" alt={question.id} src={userQuestion.response}/>
                    </div>
                </Card>
            </div>
        )
    }
    else if (responseType !== 6) {
        return (
            <div className='m-q-container'>
                <Card raised elevation={24} sx={{ bgcolor: "#FCFAFF" }} className='m-q-card'>
                    <div className='m-q-body'>
                        {question.body}
                    </div>
                    <div className='m-q-dial'>
                    </div>
                    <div className='m-q-response'>
                        {userQuestion.response !== undefined ? (userQuestion.response) : ("loading")}
                    </div>
                </Card>
    
            </div>
        )
    }

    return null;
}





