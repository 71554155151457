import { Button } from "reactstrap"
import "./WaitingPage.css"
import { sendEmailLoginToken } from "../../../managers/authManager";
import { useState } from "react";

export const WaitingPage = ({email}:any) => {

    const [isResent, setIsResent] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)

    const handleResend = (e:any) => {
        e.preventDefault();
        setButtonClicked(true)
        sendEmailLoginToken(email).then((pass) => {
          if(pass){
            setIsResent(true)
          }
          else{
          }
        })
      };


    return (
        <>
            {isResent === false ? 
            (
                <div className="w-main">
                    <div className="w-header">
                        An Email has been sent to
                    </div>
                    <div className="w-header w-email">
                        {email}
                    </div>
                    <div className="w-header">
                        Please check your inbox and follow the steps provided.
                    </div>
        
                    {buttonClicked ? 
                        (<Button disabled className="w-button">Resend Email</Button>)
                        :
                        (<Button onClick={handleResend} className="w-button">Resend Email</Button>)}
                    
                </div>
            ):(
                <div className="w-main">
                <div className="w-header">
                    The Email has be resent to
                </div>
                <div className="w-header w-email">
                    {email}
                </div>
                <div className="w-header">
                    Please check your inbox and follow the steps provided.
                </div>
            </div>
            )}
        </>
    )
}