import { useState } from "react";
import { emailRegistration } from "../../../managers/authManager";
import { Link } from "react-router-dom";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import "./Register.css"
import { WaitingPage } from "./WaitingPage";

export default function Register({ setLoggedInUser }: any) {
  const [email, setEmail] = useState("");

  const [registrationFailure, setRegistrationFailure] = useState(false);
  const [waitingPage, setWaitingPage] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault();

    emailRegistration(email).then((pass) => {
      if (pass) {
        setWaitingPage(true)
      }
      else {
        setRegistrationFailure(true)
      }
    })
  };

  return (
    <div className="r-main">
      {waitingPage === true ?
        (
          <>
            <WaitingPage email={email} />
          </>
        ) : (
          <div className="r-container container" style={{ maxWidth: "500px", marginTop: "15rem", scale: "1.3" }}>
            <div className="r-header">
              Sign Up
            </div>
            <FormGroup>
              <Label className="r-label">Email</Label>
              <Input
                className="r-input"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormGroup>
            <p style={{ color: "red" }} hidden={!registrationFailure}>
              Registration Failure
            </p>
            <div className="r-footer">
              <Button
                className="r-button"
                color="primary"
                onClick={handleSubmit}
              >
                Register
              </Button>
              <p className="r-f-note">
                Already signed up? Log in <Link to="/login">here</Link>
              </p>
              <p className="r-f-note">
                Register with a  <Link to="enp">Custom Key</Link>
              </p>
            </div>
          </div>
        )}
    </div>
  );
}
